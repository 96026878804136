
export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"


export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_PROFILE_USER';

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';

export const LIST_MEETINGS = 'LIST_MEETINGS';
export const GET_MEETING = 'GET_MEETING';
export const SCHEDULE_MEETING = 'SCHEDULE_MEETING';
export const UPDATE_MEETING = 'UPDATE_MEETING';

export const PROCESS_AUDIO = 'PROCESS_AUDIO';
export const SAVE_USER = 'SAVE_USER';

export const GET_EVALUATION = "GET_EVALUATION";